/* Provide sufficient contrast against white background */
/* a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
} */





:root {
  --tema: "light";
  color-scheme: var(--tema);
  --borderRadius: 10px;
}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Medium.ttf');
}

body {
  font-family: 'Roboto';
}

button:focus {
  outline: 0;
}


/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.titulosVistas{
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
}

hr{
  margin-top: 0.5rem;
}


.buttonActionTable{
    position: absolute;
    top: 0;
    bottom:0;
    right:0;
    left:0;
    background: transparent;
    border: none;
    outline: 0;
}

.buttonActionTable:focus{
    outline: 0;
}
.buttonActionTable:hover{
  background: rgba(28, 111, 194, .05);
}


.ultimaTareaEditada {
  animation: fade .75s ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  .titulosVistas{
    font-size: 1rem;
  }
}


.MuiSpeedDialAction-staticTooltip .MuiSpeedDialAction-staticTooltipLabel{
  font-size: 0.7rem;
}

button.MuiButton-root {
  border-radius: var(--borderRadius);
}

div.MuiChip-root {
  border-radius: var(--borderRadius);
}