.container-img {
    max-width: 50%;
    margin: 0 auto;
    height: calc(100vh - 68.5px);
    display: grid;
    place-content: center;
  }
  
  .container-img picture {
    text-align: center;
  }
  
  .container-img picture .img {
    width: 100%;
    height: auto;
    object-fit: contain;
    opacity: .25;
  }
  
  @media screen and (max-width: 900px) {
    .container-img {
      height: calc(100vh - 64px);
    }
  
    .container-img {
      max-width: 70%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .container-img {
      height: calc(100vh - 56px);
    }
  
    .container-img {
      max-width: 100%;
    }
  }